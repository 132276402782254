import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { WiSleet } from 'react-icons/wi';

export default function AppFooter()
{
    const [showTopBtn, SetShowTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 400)
                SetShowTopBtn(true);
            else
                SetShowTopBtn(false);
        })
    }, [])

    function goTop()
    {
        window.scrollTo({
            top:0,
            behaviour: 'smooth'
        })
    }

    return(
        <footer id='footer'>
            <Container fluid>
                <div className='copyright'> copyright &copy; 2024 Anton Pinzhin </div>
                <div className='socials'>
                    <ul>
                        <li>
                            <a href='https://vk.com/solardevelopers'><i className="fab fa-vk"></i></a> 
                        </li>
                        <li>
                            <a href=''><i className=" fab fa-telegram"></i></a> 
                        </li>
                        <li>
                            <a href=''><i className="fab fa-whatsapp"></i></a> 
                        </li>
                    </ul>
                </div>

                {
                    showTopBtn && (<div className='go-top' onClick={goTop}></div>)
                }
            </Container>
        </footer>
    )
}
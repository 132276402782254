// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBo1QJso5t8QSc4_wXcCUubfkC_x8-DlT4",

  authDomain: "energy-group-tver.firebaseapp.com",

  projectId: "energy-group-tver",

  storageBucket: "energy-group-tver.appspot.com",

  messagingSenderId: "712028116158",

  appId: "1:712028116158:web:d54e9adf8b3641f9a3baef"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default getFirestore();
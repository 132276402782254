import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


export default function AppHeader() {
    

    return (
      <header id="header">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="#home"> ENERGY GROUP TVER </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#home">Главная</Nav.Link>
                <Nav.Link href="#about">О нас</Nav.Link>
                <Nav.Link href="#services">Услуги</Nav.Link>
                <Nav.Link href="#works">Работы</Nav.Link>
                <Nav.Link href="#testimonials">Отзывы</Nav.Link>
                <Nav.Link href="#pricing">Прайс</Nav.Link>
                <Nav.Link href="#contact">Контакты</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    )
}

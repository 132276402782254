import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function AppModal(props)
{
    return (
        <Modal className='block'
          {...props}
          size={props.size}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton >
            <Modal.Title id="contained-modal-title-vcenter">
              {props.title}
            </Modal.Title>
          </Modal.Header >
          <Modal.Body className='text-center'>
            <h4>{props.subtitle}</h4>
            <Image  src={props.photo} />
            <p> {props.description} </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      )
}
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Button, Col, Form, Row } from 'react-bootstrap';

import AppModal from './Modals';


export default function Sendler()
{
    const [show, setShow] = useState(false); 
    const [message, setMessage] = useState('');
    const [messegeBody, setMessegeBody] = useState('');

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        
        emailjs
          .sendForm('energy-group-tver', 'template_gcinjdw', form.current, {
            publicKey: 'P-FacVbg7GehELOzS',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              setMessage('Ваше сообщение успешно отправлено.')
              setMessegeBody('Если вы указали верные данные, то мы с вами свяжемся в ближайшее время. Если этого не произошло, попробуйте другой способ связи.');
              setShow(true)
              e.target.reset();
            },
            (error) => {
              console.log('FAILED...', error.text);
              setMessage('Ваше сообщение не было отправлено.');
              setMessegeBody('Не получилось отправить нам сообщение. :( Попробуйте связаться с нами другим путем.');
              setShow(true)
            },
          );
      };

      return (
        <>
        <Form ref={form} onSubmit={sendEmail} className='contact-form'>
                <Row>
                    <Col sm={4}>
                        <Form.Control type="text" name="user_name" placeholder="Укажите имя"  required/>
                    </Col>
                    <Col sm={4}>
                        <Form.Control type="email" name="user_email" placeholder="Укажите адресс почту" required/>
                    </Col>
                    <Col sm={4}>
                        <Form.Control type='tel' name='user_telephone' placeholder="Укажите номер телефона" required/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form.Control as="textarea" name="message" placeholder="Текст сообщения" required/>
                    </Col>
                </Row>
                <div className='btn-holder'>
                    <Button type='submit' value="Send" > Отправить </Button>
                </div>
            </Form> 

            <AppModal
                title={message}
                description={messegeBody}
                size='xl'
                show={show}
                onHide={() => setShow(false)}
            /> 
        </>
      )

}
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import ProgressBar from 'react-bootstrap/ProgressBar';

import img1 from '../assets/img_about1.jpg';

export default function AppAbout()
{
    const speed = 90;
    const quality = 85;
    const Work = 65;

    return (
    <section id='about' className='block about-block'>
        <Container fluid>
            <div className='title-holder'>
                <h2>О нас</h2>
                <div className='subtitle'>Узнать больше о нас</div>
            </div>
        <Row>
            <Col sm={6}>
                <Image src={img1} />
            </Col>
            <Col sm={6}>
                <p> Energy group Tver` — 
                    многогранный технический проект. 
                    Еще 7 лет назад наши услуги были ограничены, но сейчас, 
                    с высокой долей вероятности, мы можем решить практически любую проблему.
                    Мы уделяем большое внимание эстетической стороне, 
                    технической оснастке,следим за новыми разработками в своих сферах деятельности.</p>
                <p> Мы постоянно обучаемся и осваиваем новые технологии, 
                    чтобы обеспечивать нашим клиентам «КОМФОРТ и БЕЗОПАСНОСТЬ». 
                    Мы стараемся найти подход к каждому нашему клиенту.</p>
                <div className='progress-block'>
                    <h4>Скорость Работы</h4>
                    <ProgressBar now={speed} label={`${speed}%`} />
                </div>    
                <div className='progress-block'>
                    <h4>Качество Работы</h4>
                    <ProgressBar now={quality} label={`${quality}%`} />
                </div>  
                <div className='progress-block'>
                    <h4>Клиентоориентированность</h4>
                    <ProgressBar now={Work} label={`${Work}%`} />
                </div>              
            </Col>
        </Row>
        </Container>
    </section>
    )
}
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ListGroup from 'react-bootstrap/ListGroup';
import ReactWhatsapp from 'react-whatsapp';
import { Button } from 'react-bootstrap';


export default function AppPricing(props)
{
    return(
        <section id='pricing' className='block pricing-block'>
        <Container fluid>
            <div className='title-holder'>
                <h2>Цены и Услуги</h2>
                <div className='subtitle'>лучшие цены и тарифные планы</div>
            </div>
        <Row>
            {
                props.data.map(item => {
                    return(
                        <Col sm={4} key={item.id}>
                            <div className='heading'>
                                <h4>{item.plan}</h4>
                            </div>
                            <div className='content'>
                                <ListGroup>
                                {
                                    item.features.map((feature, index) => {
                                        return(
                                            <ListGroup.Item key={index}>{feature}</ListGroup.Item>
                                        )
                                    })
                                }
                                </ListGroup>
                            </div>
                            <div className='btn-holder'>
                                <ReactWhatsapp className= "btn btn-primary fs-5" number="+7-904-022-2020" message={item.messege + " c сайта E.T.G"}>{item.buttonText} </ReactWhatsapp>
                            </div>
                        </Col>
                    )
                })
            }
            <div className='title-holder'>
                <div className='subtitle'>Полный прайс можно скачать по кнопке</div>
            </div>
            <div className='text-center'>
                <Button className='m-3' href='https://firebasestorage.googleapis.com/v0/b/energy-group-tver.appspot.com/o/%D0%9F%D1%80%D0%B0%D0%B9%D1%81%D0%BB%D0%B8%D1%81%D1%82.xlsx?alt=media&token=361d415b-3831-4ef1-92f6-52d7fe4bfa67' download="https://firebasestorage.googleapis.com/v0/b/energy-group-tver.appspot.com/o/%D0%9F%D1%80%D0%B0%D0%B9%D1%81%D0%BB%D0%B8%D1%81%D1%82.xlsx?alt=media&token=361d415b-3831-4ef1-92f6-52d7fe4bfa67">
                    Скачать полный прайс 
                    <i className="fas fa-file">
                    </i>
                </Button>
            </div>
        </Row>
        </Container>
        </section>
    )
}
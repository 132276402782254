import React, { useEffect, useState } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import Pagination from 'react-bootstrap/Pagination';
import AppModal from "./Backend/Modals";
import { Button } from "react-bootstrap";



export default function AppWorks(props)
{

    const [random, SetRandom] = useState([

        'Мы оказываем большой спектр электромонтажных работ по различным направлениям, для различных объектов. Работаем с частными и юридическими лицами',
        'Мы занимаемся установкой цифровых и аналоговых камер, систем видеонаблюдения, установкой видеодомофонов в Твери и Тверской области. Наши монтажники обладают многолетним опытом установки камер видеонаблюдения. Установка камер видеонаблюдения — сложный процесс, требующий профессионального инструмента при установке камер, аккуратности и внимательности при прокладке кабеля, и это всё у нас есть!',
        'Мы работаем с промышленными объектами. Электромонтажные работы здесь в основном выполняются на высоте с помощью самоходных подъемников и автовышек. Наши сотрудники обладают опытом работы на таких подъёмниках как: Pekkaniska, JLG, Haulotte.',
        'Мы выполняем работы по монтажу электроснабжения и автоматизации котельных установок, узлов учета тепла, систем вентиляции и кондиционирования воздуха в Твери и Тверской области.',
        'Мы занимаемся сборкой и установкой электрических и слаботочных щитов. Помогаем с подбором оборудования для наших заказчиков. На сегодняшний день щитовое оборудование находит активное применение на различных хозяйственных объектах городского и жилищного управления, в сферах строительства и энергетики, а также в различных системах электроснабжения предприятий промышленности. Оно защищает сети при коротких замыканиях или перегрузках, управляет потребителями, обеспечивает безопасность пользователей от удара током. Поэтому при выборе электрощита нужно основывать свой выбор не только на внешнем виде, а в первую очередь на его электронное наполнение – имеющиеся в нем устройства, от качества которых зависит безопасность и надёжность работы электрического щита.'
    ]);


    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(3);


    const [modalShow, setModalShow] = useState(false);
    const [item, setItem] = useState([]);
    const [active, setActive] = useState(1);

    let items = [];
    for (let number = 1; number <= Math.ceil(props.data.length/postPerPage); number++) {
        items.push(
            <Pagination.Item key={number} onClick={() => test(number)} active={number === active}>
            {number}
            </Pagination.Item>,
        );
    }

    function test(number)
    {
        setCurrentPage(number);
        setActive(number);
    }

    const lastPostIndex = currentPage * postPerPage;
    const firstPostIndex = lastPostIndex - postPerPage;
    const currentPost =  props.data.slice(firstPostIndex, lastPostIndex);
   
    return (
        <section id='works' className='block works-block'>
        <Container fluid>
            <div className='title-holder'>
                <h2>Наши работы</h2>
                <div className='subtitle'>примеры наших работ</div>
            </div>
        <Row className='portfoliolist'>
            {
                currentPost.map(item =>{
                    return (
                        <Col sm={4} key={item.id}>
                            <div className='portfolio-wrapper'>
                                <a onClick={() => setModalShow(true, setItem(item))}>
                                    <Image className='image' src={item.image} />
                                    <div className='label text-center'>
                                        <h3>{item.title}</h3>
                                        <p>{item.subtitle}</p>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    )
                })
            }

        </Row>
        <Pagination>{items}</Pagination>
        <AppMessage description={random[Math.floor(Math.random() * random.length)]} />
        </Container>
        <AppModal
            size="lg"
            title={item.title}
            subtitle={item.subtitle}
            photo={item.image}
            show={modalShow}
            onHide={() => setModalShow(false)}
        />  
    </section>
    )
}

function AppMessage(props)
{
    return(
        <div className='title-holder my-3'>
                    <h4 className='subtitle'>message</h4>
                    <div className='subtitle'>{props.description}</div>
        </div>
    )
}
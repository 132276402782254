import AppHeader from '../Component/Header';
import AppCorousels from '../Component/Corousel';
import AppAbout from '../Component/About';
import AppServices from '../Component/Services';
import AppWorks from '../Component/Works';

import AppTestimonials from '../Component/Testimonials';
import AppPricing from '../Component/Pricing';
import AppContact from '../Component/Contact';
import AppFooter from '../Component/Footer';


import { useEffect, useState } from 'react';

import { collection, onSnapshot } from 'firebase/firestore';
import db from '../Component/Backend/FireBase';

export default function AppPage()
{

    const [corousel, OnCorouselData] = useState([]);
    const [works, OnWorklData] = useState([]);
    const [price, OnPriceData] = useState([]);
    const [testimonials, OntestimonialsData] = useState([]);


    useEffect(() => {
        onSnapshot(collection(db, 'CorouselData'),(corouselData) => {
            OnCorouselData(corouselData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))    });

        onSnapshot(collection(db, 'Works'),(worksData) => {
            OnWorklData(worksData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))    });

        onSnapshot(collection(db, 'Prices'),(priceData) => {
            OnPriceData(priceData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))    });

        onSnapshot(collection(db, 'testimonials'),(testimonialsData) => {
            OntestimonialsData(testimonialsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))    });
    },[])

    return (
        <div>
            <header id="header">
                <AppHeader />
            </header>
            <main>
                <AppCorousels data={corousel}/>
                <AppAbout />
                <AppServices />
                <AppWorks data={works}/>

                <AppTestimonials data={testimonials}/>
                <AppPricing data={price} />
                <AppContact />
            </main>
            <footer id='footer'>
                <AppFooter />
            </footer> 
        </div>
    )
}
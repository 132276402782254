import { Button, Container, Row } from "react-bootstrap";

export default function NotFoundPage()
{
    return (
        <section className='block'>
            <Container fluid>
                <div className='title-holder'>
                    <h2>404</h2>
                    <div className='subtitle'>Данная страница не найдена</div>
                </div>
                <Row>
                    <div className='text-center'>
                        <Button href='/'>Вернуться на главную</Button>
                    </div>
                </Row>
            </Container>
        </section>
    )
}
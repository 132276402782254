import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';

const testimonialsData = [
    {
        id: 1,
        name: 'Иванов И. И.',
        description: 'Настоящим письмом хотим выразить благодарность и признательность электромонтёру Хвуст Антону за профессиональное и качественное выполнение работ по установке и ремонту электрооборудования..',
        designation: 'Генеральный директор ООО «Энергосервис»'
    },
    {
        id: 2,
        name: 'Пинжин А. М',
        description: 'Мы рекомендуем Хвуст Антона как высококвалифицированного специалиста в области электротехники. Его профессионализм, ответственность и опыт работы делают его незаменимым сотрудником.',
        designation: '«GameDev Bombit» IT Школа программирования'
    },    
    {
        id: 3,
        name: 'Петров А. ф.',
        description: 'Особенно хочется отметить его умение находить нестандартные решения сложных задач. Он не боится брать на себя ответственность и всегда готов к работе в любых условиях.',
        designation: 'ООО «Электромонтаж».'
    },
]

export default function AppTestimonials(props)
{
    return (

        <section id='testimonials' className='testimonials-block ' >
            <Container fluid>
                <div className='title-holder'>
                    <h2>Рекомендации</h2>
                    <div className='subtitle'>Почему клиенты выбирают нас</div>
                </div>
                <Carousel controls={false}>
                    {
                        props.data.map(item => {
                            return(
                                <Carousel.Item key={item.id}>
                                <blockquote>
                                    <p>{item.description}</p>
                                    <cite>
                                        <span className='name'>{item.name}</span>
                                        <span className='designation'>{item.designation}</span>
                                    </cite>
                                </blockquote>
                            </Carousel.Item>
                            )
                        })
                    }

                </Carousel>
            </Container>
        </section>    
    )
}
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Sendler from './Backend/Sendler';

import ReactWhatsapp from 'react-whatsapp';


export default function AppContact()
{
    return(
        <section id='contact' className='block contact-block'>
        <Container fluid>
            <div className='title-holder'>
                <h2>Обратная связь</h2>
                <div className='subtitle'>Напишите нам</div>
            </div>
            <Sendler />
        </Container>

        <Container fluid>
            <div className='title-holder'>
            <div className='subtitle'>Другие способы связи</div>
            </div>
            <div className='contact-info'>
                <ul>
                    <li className='icon' id='fas'>
                        <Button variant='transparent' href='tel:+79040222020'>
                            <i className="fas fa-phone" ></i>
                            +7-904-022-2020
                        </Button>
                    </li>
                    <li className='holder'>
                        <Button variant='transparent' href='https://vk.com/electrictver69'>
                            <i className="fas fa-user"></i>
                            VKontakte
                        </Button>
                    </li>
                    <li>
                        <ReactWhatsapp className= "btn" number="+7-904-022-2020" message="Добрый день! (Источник E.G.T)">
                            <i className="fas fa-comment"></i>
                            WhatsApp
                        </ReactWhatsapp>
                    </li>
                </ul>
            </div>
        </Container>
        </section>
    )
}
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
    {
        id: 1,
        icons: 'fas fa-bolt',
        title: 'Электромонтажные работы',
        description: 'В квартирах \nВ коттеджах \nВ торговых центрах \nВ производственных помещениях'
    },
    {
        id: 2,
        icons: 'fas fa-battery-three-quarters',
        title: 'Установка генераторов и блоков АВР',
        description: 'Устанавливаем генераторы \nКомплекты с АВР \nГенераторы с ручным управлением \nКомплекты с защитным кожухом'
    },
    {
        id: 3,
        icons: 'fas fa-bath',
        title: 'Сборка и установка ВРУ',
        description: 'Монтируем ВРУ \nГотовые сборки \nСборка ВРУ \nМонтаж цоколей для ВРУ'
    },
    {
        id: 4,
        icons: 'fas fa-gavel',
        title: 'Строительство лотковых трасс',
        description: 'Устанавливаем лотки \nПерфорированные \nЛестничные \nЦельнометаллические'
    },
    {
        id: 5,
        icons: 'fas fa-dot-circle',
        title: 'Монтаж видеонаблюдения',
        description: 'Камеры и системы видеонаблюдения \nАналоговые \nЦифровые \nВидеодомофоны'
    },
    {
        id: 6,
        icons: 'fas fa-cube',
        title: 'Монтаж электрощитовых',
        description: 'Устанавливаем готовые сборки \nCборка из комлектующих \nУстройство полосы заземления \nПодключение вводных и отходящих линий'
    } 
]

export default function AppServices()
{
    return(
        <section id='services' className='block services-block'>
        <Container fluid>
            <div className='title-holder'>
                <h2>услуги</h2>
                <div className='subtitle'>мы предлагаем</div>
            </div>
        <Row>
            {
                servicesData.map(item => {
                    return (
                        <Col sm={4} className='holder' key={item.id}>
                        <div className='icon'>
                            <i className={item.icons}/>
                        </div>
                        <h3>{item.title}</h3>
                        <pre>{item.description}</pre>
                    </Col>
                    )
                })
            }
        </Row>
        </Container>
    </section>
    )
}
import Carousel from 'react-bootstrap/Carousel';
import { FaChevronRight } from "react-icons/fa";
import AppModal from './Backend/Modals';
import { useState } from 'react';


// var corouselData = [
//     {
//         id: 1,
//         image: require('../assets/CarouselImage/1.png'),
//         title: 'Монтаж лотковых трасс',
//         description: 'г.Ржев Заказчик “АгроПромКомплектация”',
//         link: ''
//     },
//     {
//         id: 2,
//         image: require('../assets/CarouselImage/2.png'),
//         title: 'Устройство молниезащиты',
//         description: 'г.Тверь заказчик “Palmetto Group”.',
//         link: ''
//     },
//     {
//         id: 3,
//         image: require('../assets/CarouselImage/3.png'),
//         title: 'Установка светового оборудования',
//         description: 'г.Тверь заказчик ТРЦ “Вавилон”.',
//         link: ''
//     },
//     {
//         id: 4,
//         image: require('../assets/CarouselImage/4.png'),
//         title: 'Установка трековых систем',
//         description: 'г.Москва заказчик “Decart Design”.',
//         link: ''
//     },
//     {
//         id: 5,
//         image: require('../assets/CarouselImage/5.png'),
//         title: 'Установка дизайнерской люстры',
//         description: 'г.Москва заказчик “Decart Design”..',
//         link: ''
//     }
// ]

// поменять hero-block на corousel-block
export default function Corousel(props){
    
    const [modalShow, setModalShow] = useState(false);
    const [item, setItem] = useState([]);

    return (
        <section id='home' className='hero-block'>
            <Carousel>
                {
                    props.data.map(item => {
                        return (
                            <Carousel.Item key={item.id} >
                                        <img
                                            className="d-block w-100"
                                            src={item.image}
                                            alt={"Slide + " + item.id}
                                        />
                            <Carousel.Caption>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                                <a className='btn btn-primary' onClick={() => setModalShow(true, setItem(item))} >Подробнее <i className="fas fa-chevron-right"></i></a>
                            </Carousel.Caption>
                            </Carousel.Item>
                        )
                    }) 
                }
        </Carousel>

        <AppModal
            title={item.title}
            size='xl'
            subtitle={item.description}
            photo={item.image}
            show={modalShow}
            onHide={() => setModalShow(false)}
        />  
      </section>
    )
}

